<template>
  <div class="margen">
    <v-row>
      <v-col class="info-card">
        <v-card
          width="80%"
          height="200px"
          elevation="24"
          :img="require('../assets/Area/mineria.svg')"
          class="white--text mx-auto text-center"
          @click="filtrar('1');"
        >
          <h2 class="area">Mining and Manufacturing Applications</h2>
        </v-card>
      </v-col>

      <v-col class="info-card">
        <v-card
          width="80%"
          height="200px"
          elevation="24"
          :img="require('../assets/Area/imagenes.svg')"
          class="white--text mx-auto text-center"
          @click="filtrar('2');"
        >
          <h2 class="area">Biomedical Image Processing and Classification</h2>
        </v-card>
      </v-col>

      <v-col class="info-card">
        <v-card
          width="80%"
          height="200px"
          elevation="24"
          :img="require('../assets/Area/neurociencia.svg')"
          class="white--text mx-auto text-center"
          @click="filtrar('3');"
        >
          <h2 class="area">Neuroscience</h2>
        </v-card>
      </v-col>
    </v-row>
        <v-card
          dark
          class="mx-auto mt-5 text-center info-card2"
          width="90%"
          color="transparent"
          elevation="24"
        >
          <h1>PROJECTS</h1>
        </v-card>
        <v-list three-line color="transparent">  
            <v-list-item v-for="(item,i) in proyectosAct" :key="i" class="my-9 info-card3">
            <v-card class="mx-auto" width="92%" color="#f6f4f5" elevation="24">
              <v-list-item-content>
                <v-list-item-action-text ><h2>{{item.nombre}}</h2></v-list-item-action-text>
                <br/>
                <v-list-item-subtitle>{{item.periodo}}</v-list-item-subtitle>
                <br/>
                <v-list-item-subtitle>{{item.fondo}} ({{item.codigo}})</v-list-item-subtitle>
              </v-list-item-content>
            </v-card>
            </v-list-item>
        </v-list>
  </div>
</template>

<script>

export default{
  name:'Projects',
  data () {
    return {
      proyectosAct: [],
      proyectos:[
        {
          fondo:'STIC-AMSUD',
          codigo:'22STIC-04',
          nombre:'Domain adaptation for cell segmentation and classification using weakly supervised machine learning',
          periodo:'2022-2024',
          area:'2'
        },
        {
          fondo:'FONDECYT',
          codigo:'11190851',
          nombre:'Deep learning models for breast digital pathology',
          periodo:'2020-2023',
          area:'2'
        },
        {
          fondo:'PAI',
          codigo:'77180012',
          nombre:'Fortalecimiento del área de aprendizaje de máquinas en pre y posgrado e investigación del Departamento de Ingeniería Informática',
          periodo:'2019-2022',
          area:'1'
        },
        {
          fondo:'STIC-AMSUD',
          codigo:'19STIC-04',
          nombre:'Optimized deep learning based representations for computer vision problems',
          periodo:'2018-2019',
          area:'3'
        },
        {
          fondo:'FONDECYT',
          codigo:'3160559',
          nombre:'Generation of biomedical gold-standards using supervised learning based on multiple experts',
          periodo:'2015-2019',
          area:'2'
        },
        {
          fondo:'CORFO Programa de Innovación en Manufactura Avanzada',
          codigo:'18PTECMA-102646',
          nombre:'Línea 07: Sistema Predictivo de apoyo a la Gestión de Mantenimiento y de Procesos Industriales de Manufactura, basado en herramientas de Inteligencia Computacional',
          periodo:'2019-2023',
          area:'1'
        },
        {
          fondo:'FONDEF-Minería',
          codigo:'IT16M10008',
          nombre:'Sistema predictivo de apoyo a la gestión de mantenimiento  de equipamiento minero basado en herramientas de inteligencia computacional',
          periodo:'2017-2019',
          area:'1'
        },
        {
          fondo:'FONDEF-IDeA',
          codigo:'CA13I10121',
          nombre:'Herramienta de predicción de indicadores de disponibilidad para gestión de activos mineros',
          periodo:'2014-2016',
          area:'1'
        },
        {
          fondo:'FONDECYT',
          codigo:'1090316',
          nombre:'Comparative study of Support Vector Machine and Neural Networks for Nonlinear System Identification and Observer Design',
          periodo:'2009-2012',
          area:'3'
        },
        {
          fondo:'FONDECYT',
          codigo:'11190822',
          nombre:'Model-based analysis of a novel modality of spinal cord stimulation for the treatment of chronic pain',
          periodo:'2019-2022',
          area:'3'
        }, 
      ]
    }
  },
  methods:{
    filtrar(id){
      var i = Number;
      this.proyectosAct = [];

      for(i=0; i < this.proyectos.length ; i++){
        if( id == this.proyectos[i].area){
          this.proyectosAct.push(this.proyectos[i])
        }
      }
    }
  },
  mounted(){
    this.proyectosAct = this.proyectos
  }
}

</script>

<style scoped>
  .margen{
    margin-bottom:96px;
    margin-top:20px;
  }
  .info-card{
      opacity: 0;
      animation: slide-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @keyframes slide-top {
      0% {
        opacity: 0;
        transform: translateY(1000px);
      }
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    } 
  .info-card2{
      opacity: 0;
      animation: slide-top2 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @keyframes slide-top2 {
      0% {
        opacity: 0;
        transform: translateY(1000px);
      }
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    }

  .info-card3{
      opacity: 0;
      animation: slide-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @keyframes slide-top {
      0% {
        opacity: 0;
        transform: translateY(1000px);
      }
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    } 

  .area{
    background-color: #000000;
    margin: 0 auto;
    width: 100%;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  h3{
    margin: 0 8px;
    font-size:120%;
    font-weight: normal;
  }
  h4{
    margin: 0 8px;
    font-size: 110%;
    color:grey;
    font-weight: normal;
  }
</style>
